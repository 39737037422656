import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as actions from "../../../Redux/Actions";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import EditEmployeeStatus from "./EditEmployeeStatus";
import { icons } from "../../../assets/index";
import { Card } from "react-bootstrap";
import { Strings } from "../../../constant/Strings";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import { TiArrowUnsorted } from "react-icons/ti";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import "./BenchMgmt.scss";
import EditButton from "../../../component/Buttons/EditButton/EditButton";
import TablePagination from "../../../component/TablePagination/TablePagination";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import MainCard from "../../../component/MainCard/MainCard";
import BackButton from "../../../component/Buttons/BackButton";
import useLoadingIndicator from "../../../component/Loader/Loader";
import { timeDateFormat } from "../../../component/FormatDate";
const EmployeeProjectDetails = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [info, setInfo] = useState([]);
  const [page, setPage] = useState<Number>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalEntries, setTotalEntries] = useState("");
  const [showEditEmployeeStatus, setShowEditEmployeeStatus] = useState(false);
  const role = localStorage.getItem("role");
  const rmgMakerChecker = role && role.includes("rmg-bench");
  const handleEditEmployeeStatus = (row: any) => {
    setShowEditEmployeeStatus(true);
  };
  const { loadingComponent, apiCallCompleted } = useLoadingIndicator();
  const { status, details_status, employee_name } = Strings;
  const hideEditStatusDetails = () => {
    setShowEditEmployeeStatus(false);
  };
  const employeeProjectDetails = useSelector(
    (state: any) => state.employeeReducer.getProjectDetailsByEmployeeId
  );
  useEffect(() => {
    if (employeeProjectDetails && employeeProjectDetails.data) {
      setInfo(employeeProjectDetails.data);
      setTotalRecords(employeeProjectDetails.data.length);
    }
  }, [employeeProjectDetails, info]);
  const resourceName = localStorage.getItem("resourceName");

  const dispatch = useDispatch();
  const handleProjectDetails = (id: any) => {
    //  dispatch(actions.getProjectRepositoryByIdAction(id));
    navigate(`/resource-adding/${id}`);
  };
  const employeeEditDetails = useSelector(
    (state: any) => state.employeeReducer.editEmployeeData
  );
  useEffect(() => {
    const size = 10;
    dispatch(
      actions.getProjectByEmployeeIdAction(id, page, size, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res.error.error_description);
        } else if (res.code === 200) {
          setTotalPages(res.meta_data.totalPageCount);
          setTotalEntries(res.meta_data.totalEntries);
        }
      })
    );
  }, [page, employeeEditDetails]);

  const EmployeeProjectHistoryColumns: any = [
    {
      Header: "Project Name",
      accessor: "project && project[0]?.projectName",
      Cell: (Action: any) => {
        const projectName = Action.row.original.project[0]?.projectName;
        const projectId = Action.row.original.project[0]?._id;
        return (
          <div
            className="hyperlink"
            onClick={() => {
              localStorage.setItem("routeType", "BenchMgmt");
              localStorage.setItem("routeTypeId", String(id));
              handleProjectDetails(projectId);
            }}
          >
            {projectName}
          </div>
        );
      },
    },
    {
      Header: "Project Code",
      accessor: "project[0]?.projectCode",
      Cell: (Action: any) => {
        const projectCode = Action.row.original.project[0]?.projectCode;
        return <div>{projectCode}</div>;
      },
    },
    {
      Header: "Sub Project Code",
      accessor: "project[0]?.subProjectCode",
      Cell: (Action: any) => {
        const subProjectCode = Action.row.original.project[0]?.subProjectCode;
        return <div>{subProjectCode}</div>;
      },
    },
    {
      Header: "Customer Code",
      accessor: "project[0]?.customerCode",
      Cell: (Action: any) => {
        const customerCode = Action.row.original.project[0].customerCode;
        return <div>{customerCode || "-"}</div>;
      },
    },
    {
      Header: "Resource Start Date",
      accessor: "startDate",
      Cell: (Action: any) => {
        const startDate = Action.row.original.startDate;
        return timeDateFormat(startDate);
      },
    },
    {
      Header: "Resource End Date",
      accessor: "endDate",
      Cell: (Action: any) => {
        const endDate = Action.row.original.endDate;
        return endDate ? timeDateFormat(endDate) : "";
      },
    },
    {
      Header: "Resource PO End Date",
      accessor: "poEndDate",
      Cell: (Action: any) => {
        const endDate = Action.row.original.poEndDate;
        return endDate ? timeDateFormat(endDate) : "-";
      },
    },
  ];
  const data = info;
  const columns = useMemo(() => EmployeeProjectHistoryColumns, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setPageSize,
    state: { pageSize },
  } = useTable(
    { columns, data, initialState: { pageSize: 10, pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const handlePageChange = (newPage: any) => {
    setPage(newPage);
  };
  return (
    <>
      {loadingComponent()}
      <MainCard>
        <Card className="project-details-card mt-3">
          <div className="row">
            <div className="col-md-10">
              <div className="row">
                <BackButton navigateTo={"/bench-management"} />
                <div className="project-title title-color">
                  {employee_name} :
                </div>
                <div className="employee-name">{resourceName}</div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="row">
                {rmgMakerChecker && (
                  <div className="justify-content-end edit-icon-wrapper">
                    <EditButton
                      title="Edit Status"
                      icon={icons.edit_orange_icon}
                      onClick={() =>
                        handleEditEmployeeStatus(
                          employeeProjectDetails?.data[0]?.employeeRef
                        )
                      }
                    />
                  </div>
                )}
                <div className="mt-4 d-flex">
                  <div className="col-md-7 status-title title-color">
                    {status} :
                  </div>
                  <span className="col-md-5 status-title">
                    {employeeProjectDetails?.data[0]?.resource[0]?.status}
                  </span>
                </div>
                <div className="d-flex">
                  <div className="col-md-7 status-title title-color">
                    {details_status} :
                  </div>
                  {employeeProjectDetails?.data[0]?.resource[0]?.recordStatus ==
                    "Absconded" ||
                  employeeProjectDetails?.data[0]?.resource[0]?.recordStatus ==
                    "Resigned" ? (
                    <>
                      <span className="col-md-5 status-title">
                        {
                          employeeProjectDetails?.data[0]?.resource[0]
                            ?.recordStatus
                        }
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="col-md-5 status-title">
                        {
                          employeeProjectDetails?.data[0]?.resource[0]
                            ?.recordStatus
                        }
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Card>
        <div className="table-responsive">
          <div className="table">
            <table
              className="table-main table table-sm mt-3"
              {...getTableProps()}
            >
              <thead className="table-head">
                {headerGroups.map((headerGroup) => (
                  <tr
                    className="table-head-row"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column: any) => (
                      <th
                        className="table-head-row"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        scope="col"
                      >
                        {column.render("Header")}

                        <span>
                          {column.id !== "action" &&
                          column.id !== "id" &&
                          column.id !== "remarks" ? (
                            column.isSorted ? (
                              column.isSortedDesc ? (
                                <TiArrowUnsorted />
                              ) : (
                                <TiArrowUnsorted />
                              )
                            ) : (
                              <TiArrowUnsorted />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="table-body" {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="table-body-row" {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="show-entries-wrapper">
          <ShowEntries
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalRecords={totalRecords}
            totalEntries={totalEntries}
          />
          <TablePagination
            totalPages={totalPages}
            currentPage={page}
            onClick={handlePageChange}
          />
        </div>
        <ModalComponents
          show={showEditEmployeeStatus}
          onHide={hideEditStatusDetails}
          title="Edit Employee Status"
          modalBody={
            <EditEmployeeStatus
              apiCallCompleted={apiCallCompleted}
              data={info[0]}
              eId={employeeProjectDetails?.data[0].employeeRef}
              hideEditStatusDetails={hideEditStatusDetails}
            />
          }
        />
      </MainCard>
    </>
  );
};

export default EmployeeProjectDetails;
