import { useEffect, useState } from "react";
import ProjectrepositoryTable from "./ProjectrepositoryTable";
import { useDispatch } from "react-redux";
import * as actions from "../../../Redux/Actions";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import useLoadingIndicator from "../../../component/Loader/Loader";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import { useSearchParams } from "react-router-dom";
import hanldeUnauthorizedUser from "../../../component/AccessConditions/UnauthorizedUser";
import "../ProjectRepository/ProjectRepository.scss";

const ProjectRepo = () => {
  const { unAuthorizeUser } = hanldeUnauthorizedUser();
  const [queryParameters] = useSearchParams();
  const querySearchKey = queryParameters.get("projectSearchKey");
  const querySearchValue = queryParameters.get("projectSearchValue");
  const { loadingComponent } = useLoadingIndicator();
  const dispatch = useDispatch();
  const [page, setPage] = useState<Number>(1);
  const [searchKey, setSearchKey] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [filterValue, setFilterValue] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const [pageSize, setPageSize] = useState<any>(10);
  const [totalEntries, setTotalEntries] = useState<any>();

  const addProjectDetails = useSelector(
    (state: any) => state.employeeReducer.addProjectDetails
  );
  const editProjectDetails = useSelector(
    (state: any) => state.employeeReducer.editProjectDetails
  );
  const deleteProjectDetails = useSelector(
    (state: any) => state.employeeReducer.deleteProjectDetails
  );
  const addProjectInvoiceDetails = useSelector(
    (state: any) => state.employeeReducer.addProjectInvoiceDetails
  );
  const editProjectInvoiceDetails = useSelector(
    (state: any) => state.employeeReducer.editProjectInvoiceDetails
  );
  const deleteProjectInvoiceDetails = useSelector(
    (state: any) => state.employeeReducer.deleteProjectInvoiceDetails
  );
  const projectBulkUploadDetails = useSelector(
    (state: any) => state.employeeReducer.projectBulkUploadDetails
  );
  const [onclickSearch, setOnclickSearch] = useState(false);
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };
  const condition: boolean =
    (filterValue.length == 0 && searchValue == "") ||
    (filterValue.length > 0 && onclickSearch);
  const filterKey = filterValue.map((ele: any) => ele.value);
  useEffect(() => {
    unAuthorizeUser();
    if (querySearchKey != null && querySearchValue != null) {
      dispatch(
        actions.getProjectRepositoryAction(
          pageSize,
          page,
          querySearchKey,
          querySearchValue,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else if (res.code === 200) {
              setTotalPages(res?.meta_data?.totalPageCount);
              setTotalEntries(res?.meta_data?.totalEntries);
              setOnclickSearch(false);
            }
          }
        )
      );
    } else {
      dispatch(
        actions.getProjectRepositoryAction(
          pageSize,
          page,
          filterKey,
          searchValue,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else if (res.code === 200) {
              setTotalPages(res?.meta_data?.totalPageCount);
              setTotalEntries(res?.meta_data?.totalEntries);
              setOnclickSearch(false);
            }
          }
        )
      );
    }
  }, [
    projectBulkUploadDetails,
    addProjectDetails,
    editProjectDetails,
    deleteProjectDetails,
    addProjectInvoiceDetails,
    editProjectInvoiceDetails,
    deleteProjectInvoiceDetails,
    page,
    searchValue,
    searchKey,
    filterValue,
    pageSize,
    condition,
    filterKey,
  ]);
  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };

  const filterChange = (selectedOption: any) => {
    if (selectedOption) {
      if (filterValue.find((obj: any) => obj.value == selectedOption.value)) {
        setFilterValue((prevValue: any) =>
          prevValue.filter((obj: any) => obj.value != selectedOption.value)
        );
      } else {
        setFilterValue((prevValue: any) => [...prevValue, selectedOption]);
      }
    }
  };
  return (
    <>
      {loadingComponent()}
      <ProjectrepositoryTable
        totalPages={totalPages}
        onPage={page}
        handlePageChange={handlePageChange}
        searchKey={searchKey}
        searchValue={searchValue}
        handleSearch={handleSearch}
        setSearchKey={setSearchKey}
        filterValue={filterValue}
        filterChange={filterChange}
        pagePerSize={pageSize}
        setPagePerSize={setPageSize}
        totalEntries={totalEntries}
        onSearchClicked={onSearchClicked}
        editProjectDetails={editProjectDetails}
        filterKey={filterKey}
      />
      <ToastContainer />
    </>
  );
};

export default ProjectRepo;
