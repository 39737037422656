import React, { useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../Redux/Actions/index";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../component/Toasts/Toasts";
import { AiOutlineCheck } from "react-icons/ai";
import ModalComponents from "../../../../component/ModalComponent/ModalComponent";
import ModalWarning from "../../../../component/MakerChecker/ModalWarning";
import ReasonForRejection from "../../../../component/MakerChecker/ReasonForRejection";
import Select from "react-select";
import {
  Strings,
  projectDetailsLabels,
  requiredFeild,
  addProjectErrorsValidations,
  geographyDropdown,
  projectTypeDropdown,
  techTypeDropdown,
  addProjectStatusDropdown,
  poValueInDropdown,
  billingRateDropdown,
} from "../../../../constant/Strings";
import {
  employeeMobileNumberValidtion,
  isEmailValid,
  isMobileValid,
  projectClientNameValidation,
  projectCustomerCodeValidation,
  projectLocationValidation,
  projectPoNumberValidation,
  projectProjectCodeValidation,
  projectProjectNameValidation,
  projectSubProjectCodeValidation,
} from "../../../../constant/Validations";
import { icons } from "../../../../assets/index";
import { timeDateFormatYMD } from "../../../../component/FormatDate";
import moment from "moment";
import { ColorRing } from "react-loader-spinner";
const EditProject = (props: any) => {
  const { projectDetails, hideEditProject, apiCallCompleted } = props;
  const oldStatus = projectDetails?.status;
  const clientRef = projectDetails?.clientRef;
  const role = localStorage.getItem("role");
  const checkerData = role?.includes("rmg-checker");
  const [btnDisable, setBtnDisable] = useState(false);
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  const [employeeInfo, setEmployeeInfo] = useState([]);
  const [hrbpInfo, setHrbpInfo] = useState([]);
  const [modalWarning, setModalWarning] = useState("");
  const [showReason, setShowReason] = useState(false);
  const [showReasonForRejection, setShowReasonForRejection] = useState(false);
  const {
    editSuccess,
    addSuccess,
    cancel,
    submit,
    uploadPoDoc,
    uploadFile,
    imageSize,
    uploadMsa,
    uploadSow,
    approve,
    reject,
    viewFile,
  } = Strings;
  const {
    poAmount,
    approvedHc,
    poNumber,
    hrbp,
    tdm,
    subProjectCode,
    projectCode,
    customerCode,
    projectName,
    clientVal,
    location,
    spocPersonName,
    spocNumber,
    spocEmail,
  } = addProjectErrorsValidations;

  const {
    geographyLabel,
    locationLabel,
    clientNameLabel,
    projectNameLabel,
    projectTypeLabel,
    projectCodeLabel,
    subProjectCodeLabel,
    CustomerCodeLabel,
    serviceLineLabel,
    deliveryManagerLabel,
    hrBusinessPartnerLabel,
    projectStartDateLabel,
    projectEndDateLabel,
    projectStatusLabel,
    purchaseOrderNoLabel,
    poStartDateLabel,
    poEndDateLabel,
    approvedHcLabel,
    currencyTypeLabel,
    billingStatusLabel,
    billingRateLabel,
    spocPersonNameLabel,
    spocContactNumberLabel,
    spocEmailIDLabel,
    remarksLabel,
  } = projectDetailsLabels;

  const [projectData, setProjectData] = useState<any>({
    geography: projectDetails.geography,
    subProjectName: projectDetails.subProjectName,
    projectName: projectDetails.projectName,
    location: projectDetails.location,
    clientName: projectDetails.client,
    tdm: "",
    hrbp: "",
    status: projectDetails.status,
    projectType: projectDetails.projectType,
    techType: projectDetails.techType,
    remarks: projectDetails.remarks,
    poValueIn: projectDetails.poValueIn,
    billingRate: projectDetails.billingRate,
    poNumber: projectDetails.poNumber,
    approvedHc: projectDetails.approvedHc,
    poAmount: projectDetails.poAmount,
    projectCode: projectDetails.projectCode,
    subProjectCode: projectDetails.subProjectCode,
    customerCode: projectDetails.customerCode,
    projectStartDate: projectDetails.projectStartDate,
    projectEndDate: projectDetails.projectEndDate,
    poStartDate: projectDetails.poStartDate,
    poEndDate: projectDetails.poEndDate,
    uploadPoNumber: projectDetails.uploadPoNumber,
    msa: projectDetails.msa,
    sow: projectDetails.sow,
    recordStatus: "Updated",
    spocPersonName: projectDetails.spocPersonName,
    spocNumber: projectDetails.spocNumber,
    spocEmail: projectDetails.spocEmail,
    updatedBy: loginId,
    projectDescription: projectDetails.projectDescription,
    actualHc: projectDetails?.actualHc,
    buHeadRef: projectDetails?.tdm,
    hrbpRef: projectDetails?.hrbp,
  });

  const dispatch = useDispatch();
  let valid: boolean = true;
  const validate = () => {
    if (projectLocationValidation(projectData.location) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        location: location,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        location: "",
      }));
    }
    if (projectClientNameValidation(projectData.client) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        client: clientVal,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        client: "",
      }));
    }
    if (projectProjectNameValidation(projectData.projectName) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        projectName: projectName,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        projectName: "",
      }));
    }
    if (projectProjectCodeValidation(projectData.projectCode) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        projectCode: projectCode,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        projectCode: "",
      }));
    }
    if (projectSubProjectCodeValidation(projectData.subProjectCode) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        subProjectCode: subProjectCode,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        subProjectCode: "",
      }));
    }

    if (projectCustomerCodeValidation(projectData.customerCode) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        customerCode: customerCode,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        customerCode: "",
      }));
    }

    if (projectPoNumberValidation(projectData.poNumber) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        poNumber: poNumber,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        poNumber: "",
      }));
    }

    if (projectData.spocPersonName) {
      if (projectClientNameValidation(projectData.spocPersonName) == false) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          spocPersonName: spocPersonName,
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({
          ...errorObj,
          spocPersonName: "",
        }));
      }
    }
    if (projectData.spocNumber) {
      if (
        projectData.geography === "India" &&
        isMobileValid(projectData.spocNumber) == false
      ) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          spocNumber: spocNumber,
        }));
        setBtnDisable(false);
      } else if (
        projectData.geography !== "India" &&
        employeeMobileNumberValidtion(projectData.spocNumber) == false
      ) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          spocNumber: spocNumber,
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({
          ...errorObj,
          spocNumber: "",
        }));
      }
    }
    if (projectData.spocEmail) {
      if (isEmailValid(projectData.spocEmail) == false) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          spocEmail: spocEmail,
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({
          ...errorObj,
          spocEmail: "",
        }));
      }
    }
  };
  const handleEditProjectSubmit = () => {
    validate();
    if (valid) {
      handleDispatch();
    }
  };

  const handleDispatch = () => {
    apiCallCompleted(false);
    const userLogin: any = localStorage.getItem("login");
    const loginId = JSON.parse(userLogin)?.id;
    const payload = {
      ...projectData,
      tdm: "",
      hrbp: "",
      PoUpdateBy: loginId,
      status:
        projectDetails.poNumber != projectData.poNumber &&
        projectData.status != "Extended"
          ? "New"
          : projectData.status,
    };
    if (oldStatus != "Extended" && projectData.status == "Extended") {
      payload.recordStatus = "New";
    }
    dispatch(
      actions.editProjectRepositoryAction(
        projectDetails.id,
        payload,
        (res: any) => {
          if (res?.error?.error_description) {
            showErrorToastMessage(res?.error?.error_description);
            apiCallCompleted(true);
          } else if (res.code === 200) {
            showSuccessToastMessage(editSuccess);
            apiCallCompleted(true);
          }
        }
      )
    );
    hideEditProject();
  };
  const [errors, setErrors] = useState({
    location: "",
    client: "",
    projectName: "",
    subProjectName: "",
    customerCode: "",
    projectCode: "",
    subProjectCode: "",
    tdm: "",
    hrbp: "",
    techType: "",
    poNumber: "",
    approvedHc: "",
    poAmount: "",
    spocPersonName: "",
    spocNumber: "",
    spocEmail: "",
    projectDescription: "",
    actualHc: "",
  });

  const addClientName = useSelector(
    (state: any) => state.employeeReducer.addClientNameDetails
  );
  const employeeManagerData = useSelector(
    (state: any) => state.employeeReducer.getEmployeeData
  );
  useEffect(() => {
    dispatch(actions.getClientNameAction());
  }, [addClientName]);
  useEffect(() => {
    dispatch(actions.getEmployeeDataAction());
  }, []);
  const [client, setClient] = useState({
    clientName: "",
  });
  useEffect(() => {
    dispatch(actions.getEmployeeDbHrbpListAction());
  }, []);

  const employeeHrbpData = useSelector(
    (state: any) => state.employeeReducer.getEmployeeDbHrbpList
  );
  useEffect(() => {
    if (employeeHrbpData && employeeHrbpData.data?.length) {
      setHrbpInfo(
        employeeHrbpData.data.map((ele: any) => ({
          value: ele._id,
          label: ele.candidateName
            ? ele.candidateName +
              " " +
              ele.middleName +
              " " +
              ele.lastname +
              "-" +
              ele.employeeId
            : ele.candidateName + " " + ele.lastname + "-" + ele.employeeId,
        }))
      );
    }
  }, [employeeHrbpData]);
  const handleDropDownClientSubmit = (e: any) => {
    e.preventDefault();
    dispatch(
      actions.addClientNameAction(client, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res.error.error_description);
        } else if (res.code === 200) {
          showSuccessToastMessage(addSuccess);
        }
      })
    );
    const inputField: any = document.getElementById("formAddOption");
    if (inputField) {
      inputField.value = "";
    }
  };

  const [clientName, setClientName] = useState([]);
  const getClientName = useSelector(
    (state: any) => state.employeeReducer.getClientNameDetails
  );

  useEffect(() => {
    if (getClientName && getClientName.data) {
      setClientName(getClientName.data);
    }
    if (employeeManagerData && employeeManagerData.data?.length) {
      setEmployeeInfo(
        employeeManagerData.data.map((ele: any) => ({
          value: ele._id,
          label: ele.candidateName
            ? ele.candidateName +
              " " +
              ele.middleName +
              " " +
              ele.lastname +
              "-" +
              ele.employeeId
            : ele.candidateName + " " + ele.lastname + "-" + ele.employeeId,
        }))
      );
    }
  }, [clientName, employeeManagerData]);

  useEffect(() => {
    if (
      projectData.geography &&
      projectData.projectName &&
      projectData.location &&
      projectData.clientName &&
      projectData.buHeadRef &&
      projectData.hrbpRef &&
      projectData.status &&
      projectData.projectType &&
      projectData.subProjectCode &&
      projectData.customerCode
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [projectData, btnDisable]);
  const [docFile, setDocFile] = useState<any>({
    uploadPoNumber: null,
    msa: null,
    sow: null,
  });

  const handleSubmitPoDocumentImage = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 20 * 1024 * 1024) {
      showErrorToastMessage("File size too large. Maximum size is 20 MB.");
      return;
    } else if (
      ![
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ].includes(fileType)
    ) {
      showErrorToastMessage(
        "File type not allowed. Allowed types are JPEG, DOC, and PDF."
      );
      return;
    } else {
      setDocFile({ ...docFile, uploadPoNumber: e.target.files[0] });
      dispatch(
        actions.projectDetailsDocUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setProjectData({
                ...projectData,
                uploadPoNumber: res.data,
              });
            }
          }
        )
      );
    }
  };

  const handleSubmitMsaImage = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 20 * 1024 * 1024) {
      showErrorToastMessage("File size too large. Maximum size is 20 MB.");
      return;
    } else if (
      ![
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ].includes(fileType)
    ) {
      showErrorToastMessage(
        "File type not allowed. Allowed types are JPEG, DOC, and PDF."
      );
      return;
    } else {
      setDocFile({ ...docFile, msa: e.target.files[0] });
      dispatch(
        actions.projectDetailsDocUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setProjectData({
                ...projectData,
                msa: res.data,
              });
            }
          }
        )
      );
    }
  };
  const handleSubmitSowImage = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 20 * 1024 * 1024) {
      showErrorToastMessage("File size too large. Maximum size is 20 MB.");
      return;
    } else if (
      ![
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ].includes(fileType)
    ) {
      showErrorToastMessage(
        "File type not allowed. Allowed types are JPEG, DOC, and PDF."
      );
      return;
    } else {
      setDocFile({ ...docFile, sow: e.target.files[0] });
      dispatch(
        actions.projectDetailsDocUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setProjectData({
                ...projectData,
                sow: res.data,
              });
            }
          }
        )
      );
    }
  };
  const handleRejectModal = (text: any) => {
    setModalWarning(text);
    setShowReasonForRejection(true);
  };
  const hideRejectModal = () => {
    setShowReasonForRejection(false);
  };
  const handleApproval = (text: any) => {
    setModalWarning(text);
    setShowReason(true);
  };
  const hideModal = () => {
    setShowReason(false);
  };

  const handleReportingManager = (selected: any) => {
    setProjectData((prevData: any) => ({
      ...prevData,
      tdm: selected,
      buHeadRef: selected.value,
    }));
  };

  const handleHrbpManager = (selected: any) => {
    setProjectData((prevData: any) => ({
      ...prevData,
      hrbp: selected,
      hrbpRef: selected.value,
    }));
  };

  useEffect(() => {
    if (
      !projectData.hrbp &&
      hrbpInfo?.length > 0 &&
      !projectData.tdm &&
      employeeInfo?.length > 0
    ) {
      const hrbp = hrbpInfo.find(
        (option: any) => option.value === projectDetails.hrbp
      );

      const tdm = employeeInfo.find(
        (option: any) => option.value === projectDetails.tdm
      );
      setProjectData({
        ...projectData,
        hrbp: hrbp,
        tdm: tdm,
      });
    }
  }, [hrbpInfo, employeeInfo, projectData, projectDetails]);

  return (
    <>
      {!projectData.hrbp && !projectData.tdm ? (
        <div className="row mt-3">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#28BCB9", "#28BCB9", "#28BCB9", "#28BCB9", "#28BCB9"]}
          />
        </div>
      ) : (
        <>
          <div className="row g-3">
            <div className="col-md-4">
              <label htmlFor="inputName4" className="form-label">
                {geographyLabel}
              </label>
              <span className="required">{requiredFeild.required}</span>
              <Dropdown
                onSelect={(eventKey: any) =>
                  setProjectData({
                    ...projectData,
                    geography: eventKey,
                  })
                }
              >
                <Dropdown.Toggle variant="light" className="form-control">
                  {projectData.geography}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {geographyDropdown.map((element: any) => (
                    <Dropdown.Item eventKey={element}>{element}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-4">
              <label htmlFor="inputName4" className="form-label">
                {locationLabel}
              </label>
              <span className="required">{requiredFeild.required}</span>
              <input
                type="text"
                className="form-control"
                value={projectData.location}
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    location: e.target.value,
                  })
                }
              />
              <p className="error">{errors.location}</p>
            </div>
            <div className="col-md-4">
              <label htmlFor="inputName4" className="form-label">
                {clientNameLabel}
              </label>
              <span className="required">{requiredFeild.required}</span>
              <Dropdown
                onSelect={(eventKey: any) =>
                  setProjectData({
                    ...projectData,
                    clientName: eventKey.split("/")[0],
                    clientRef: eventKey.split("/")[1],
                  })
                }
              >
                <Dropdown.Toggle variant="light" className="form-control">
                  {projectData.clientName
                    ? `${projectData.clientName?.slice(0, 30)}...`
                    : ""}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu">
                  {clientName?.length > 0 &&
                    clientName?.map((ele: any) => (
                      <Dropdown.Item eventKey={ele.clientName + "/" + ele._id}>
                        {ele.clientName}
                      </Dropdown.Item>
                    ))}

                  <Form>
                    <Form.Group controlId="formAddOption">
                      <div className="input-group">
                        <Form.Control
                          type="text"
                          placeholder="Enter Other"
                          style={{ marginLeft: "5px", wordWrap: "break-word" }}
                          onChange={(e) =>
                            setClient({
                              ...client,
                              clientName: e.target.value,
                            })
                          }
                          id={"formAddOption"}
                        />

                        <div className="input-group-append">
                          <button
                            type="button"
                            style={{ fontSize: "10px", height: "33px" }}
                            className="btn btn-primary"
                            onClick={handleDropDownClientSubmit}
                          >
                            <AiOutlineCheck />
                          </button>
                        </div>
                      </div>
                    </Form.Group>
                  </Form>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-4">
              <label htmlFor="inputName4" className="form-label">
                {projectNameLabel}
              </label>
              <span className="required">{requiredFeild.required}</span>
              <input
                type="text"
                className="form-control"
                value={projectData.projectName}
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    projectName: e.target.value,
                  })
                }
              />
              <p className="error">{errors.projectName}</p>
            </div>
            <div className="col-md-4">
              <label htmlFor="inputName4" className="form-label">
                Project Description
              </label>
              <textarea
                maxLength={150}
                className="form-control"
                value={projectData.projectDescription}
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    projectDescription: e.target.value.slice(0, 150),
                  })
                }
              />
              <p className="error">{errors.projectDescription}</p>
            </div>
            <div className="col-md-4">
              <label htmlFor="inputName4" className="form-label">
                {projectTypeLabel}
              </label>
              <span className="required">{requiredFeild.required}</span>
              <Dropdown
                onSelect={(eventKey: any) =>
                  setProjectData({
                    ...projectData,
                    projectType: eventKey,
                  })
                }
              >
                <Dropdown.Toggle variant="light" className="form-control">
                  {projectData.projectType}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {projectTypeDropdown.map((element: any) => (
                    <Dropdown.Item eventKey={element}>{element}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="col-md-4">
              <label htmlFor="inputName4" className="form-label">
                {projectCodeLabel}
              </label>
              <span className="required">{requiredFeild.required}</span>
              <input
                type="text"
                className="form-control"
                value={projectData.projectCode}
                onChange={(e) => {
                  const value = e.target.value;
                  const sanitizedValue = value
                    .replace(/[^&().a-zA-Z0-9\s-]/g, "")
                    .replace(/^\s+/, "")
                    .replace(/\s{2,}/g, " ");
                  setProjectData({
                    ...projectData,
                    projectCode: sanitizedValue,
                  });
                }}
              />
              <p className="error">{errors.projectCode}</p>
            </div>
            <div className="col-md-4">
              <label htmlFor="inputName4" className="form-label">
                {subProjectCodeLabel}
              </label>
              <span className="required">{requiredFeild.required}</span>
              <input
                type="text"
                className="form-control"
                value={projectData.subProjectCode}
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    subProjectCode: e.target.value,
                  })
                }
                disabled
              />
              <p className="error">{errors.subProjectCode}</p>
            </div>
            <div className="col-md-4">
              <label htmlFor="inputName4" className="form-label">
                {CustomerCodeLabel}
              </label>
              <span className="required">{requiredFeild.required}</span>
              <input
                type="text"
                className="form-control"
                value={projectData?.customerCode}
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    customerCode: e.target.value,
                  })
                }
              />
              <p className="error">{errors.customerCode}</p>
            </div>
            <div className="col-md-4">
              <label htmlFor="inputName4" className="form-label">
                {serviceLineLabel}
              </label>
              <Dropdown
                onSelect={(eventKey: any) =>
                  setProjectData({
                    ...projectData,
                    techType: eventKey,
                  })
                }
              >
                <Dropdown.Toggle variant="light" className="form-control">
                  {projectData.techType}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {techTypeDropdown.map((element: any) => (
                    <Dropdown.Item eventKey={element}>{element}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="col-md-4">
              <label htmlFor="inputName4" className="form-label">
                {deliveryManagerLabel}
              </label>
              <span className="required">{requiredFeild.required}</span>
              <div style={{ height: "1rem" }}>
                <Select
                  className="dropdown-select"
                  options={employeeInfo}
                  placeholder={
                    projectData.tdm ? projectData.tdm?.label : "Select manager"
                  }
                  isSearchable={true}
                  onChange={handleReportingManager}
                />
              </div>
            </div>

            <div className="col-md-4">
              <label htmlFor="inputName4" className="form-label">
                {hrBusinessPartnerLabel}
              </label>
              <span className="required">{requiredFeild.required}</span>
              <Select
                className="dropdown-select"
                placeholder={
                  projectData.hrbp ? projectData.hrbp?.label : "Select HRBP"
                }
                options={hrbpInfo}
                isSearchable={true}
                onChange={handleHrbpManager}
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="inputName4" className="form-label">
                {projectStartDateLabel}
              </label>
              <span className="required">{requiredFeild.required}</span>
              <input
                type="date"
                className="form-control"
                max={moment(projectData.projectEndDate)
                  .subtract(1, "day")
                  .format("YYYY-MM-DD")}
                value={timeDateFormatYMD(projectData.projectStartDate)}
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    projectStartDate: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputName4" className="form-label">
                {projectEndDateLabel}
              </label>
              <input
                type="date"
                className="form-control"
                min={moment(projectData.projectStartDate)
                  .add(1, "day")
                  .format("YYYY-MM-DD")}
                value={timeDateFormatYMD(projectData.projectEndDate)}
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    projectEndDate: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="inputName4" className="form-label">
                {projectStatusLabel}
              </label>
              <span className="required">{requiredFeild.required}</span>
              <Dropdown
                onSelect={(eventKey: any) => {
                  if (eventKey == "Extended") {
                    setProjectData({
                      ...projectData,
                      poNumber: "",
                      poStartDate: "",
                      poEndDate: "",
                      approvedHc: "",
                      billingRate: "",
                      poAmount: "",
                      uploadPoNumber: "",
                      status: eventKey,
                    });
                  } else {
                    setProjectData({
                      ...projectData,
                      status: eventKey,
                    });
                  }
                }}
              >
                <Dropdown.Toggle variant="light" className="form-control">
                  {projectData.status}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {addProjectStatusDropdown.map((element: any) => (
                    <Dropdown.Item eventKey={element}>{element}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="col-md-4">
              <label htmlFor="inputName4" className="form-label">
                {purchaseOrderNoLabel}
              </label>
              <input
                type="text"
                className="form-control"
                value={projectData.poNumber}
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    poNumber: e.target.value,
                    poStartDate: "",
                    poEndDate: "",
                    poAmount: "",
                    billingRate: "",
                    uploadPoNumber: "",
                  })
                }
              />
              <p className="error">{errors.poNumber}</p>
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="inputName4" className="form-label">
                {poStartDateLabel}
              </label>
              <input
                type="date"
                className="form-control"
                value={
                  projectData.poStartDate
                    ? timeDateFormatYMD(projectData.poStartDate)
                    : ""
                }
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    poStartDate: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="inputName4" className="form-label">
                {poEndDateLabel}
              </label>
              <input
                type="date"
                className="form-control"
                value={
                  projectData.poEndDate
                    ? timeDateFormatYMD(projectData.poEndDate)
                    : ""
                }
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    poEndDate: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="inputName4" className="form-label">
                Actual HC
              </label>
              <input
                type="text"
                className="form-control"
                value={projectData.actualHc}
                disabled
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    actualHc: e.target.value.replace(/\D/g, ""),
                  })
                }
              />
              <p className="error">{errors.actualHc}</p>
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="inputName4" className="form-label">
                {approvedHcLabel}
              </label>
              <input
                type="text"
                className="form-control"
                value={projectData.approvedHc}
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    approvedHc: e.target.value.replace(/\D/g, ""),
                  })
                }
              />
              <p className="error">{errors.approvedHc}</p>
            </div>
            <div className="col-md-4">
              <label htmlFor="inputName4" className="form-label">
                {currencyTypeLabel}
              </label>
              <Dropdown
                onSelect={(eventKey: any) =>
                  setProjectData({
                    ...projectData,
                    poValueIn: eventKey,
                  })
                }
              >
                <Dropdown.Toggle variant="light" className="form-control">
                  {projectData.poValueIn}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {poValueInDropdown.map((element: any) => (
                    <Dropdown.Item eventKey={element}>{element}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="col-md-4">
              <label htmlFor="inputName4" className="form-label">
                {billingStatusLabel}
              </label>
              <Dropdown
                onSelect={(eventKey: any) =>
                  setProjectData({
                    ...projectData,
                    billingRate: eventKey,
                  })
                }
              >
                <Dropdown.Toggle variant="light" className="form-control">
                  {projectData.billingRate}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {billingRateDropdown.map((element: any) => (
                    <Dropdown.Item eventKey={element}>{element}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-4">
              <label htmlFor="inputName4" className="form-label">
                {billingRateLabel}
              </label>
              <input
                type="text"
                className="form-control"
                value={projectData.poAmount}
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    poAmount: e.target.value,
                  })
                }
              />
              <p className="error">{errors.poAmount}</p>
            </div>
            <div className="col-md-4">
              <label htmlFor="inputName4" className="form-label">
                {spocPersonNameLabel}
              </label>
              <input
                type="text"
                className="form-control"
                value={projectData.spocPersonName}
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    spocPersonName: e.target.value,
                  })
                }
              />
              <p className="error">{errors.spocPersonName}</p>
            </div>
            <div className="col-md-4">
              <label htmlFor="inputName4" className="form-label">
                {spocContactNumberLabel}
              </label>
              <input
                type="text"
                maxLength={projectData.geography == "India" ? 10 : 15}
                className="form-control"
                value={projectData.spocNumber}
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    spocNumber: e.target.value,
                  })
                }
              />
              <p className="error">{errors.spocNumber}</p>
            </div>
            <div className="col-md-4">
              <label htmlFor="inputName4" className="form-label">
                {spocEmailIDLabel}
              </label>
              <input
                type="email"
                className="form-control"
                value={projectData.spocEmail}
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    spocEmail: e.target.value,
                  })
                }
              />
              <p className="error">{errors.spocEmail}</p>
            </div>
            <div className="col-md-4">
              <label htmlFor="inputName4" className="form-label">
                {remarksLabel}
              </label>
              <input
                className="form-control"
                value={projectData.remarks}
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    remarks: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-4">
              <label className="form-label">{uploadPoDoc}</label>
              <div>
                <input
                  type="file"
                  className="form-control"
                  onChange={handleSubmitPoDocumentImage}
                  hidden
                  id="actual-btn-po"
                />
                <label
                  htmlFor="actual-btn-po"
                  className="btn btn-outline-secondary buttonsize"
                >
                  {uploadFile}
                </label>
              </div>
              <label className="form-label">
                {imageSize}
                <span className="required">{requiredFeild.required}</span>
              </label>
              <a
                className="buttonsize"
                href={projectData.uploadPoNumber?.document_path_url}
                target="_blank"
              >
                {projectData.uploadPoNumber?.document_path_url ? (
                  <>
                    <div className="d-flex flex-row justify-content-start align-items-center">
                      <span>{viewFile}</span>
                      <img
                        className="cursor"
                        src={icons.close_icon}
                        onClick={() =>
                          setProjectData((obj: any) => ({
                            ...obj,
                            uploadPoNumber: "",
                          }))
                        }
                        alt="viewFile"
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </a>
              <p className="image-size-condition">
                {docFile.uploadPoNumber?.name}
              </p>
            </div>

            <div className="col-md-4">
              <label className="form-label">{uploadMsa}</label>
              <div>
                <input
                  type="file"
                  className="form-control"
                  onChange={handleSubmitMsaImage}
                  hidden
                  id="actual-btn-msa"
                />
                <label
                  htmlFor="actual-btn-msa"
                  className="btn btn-outline-secondary buttonsize"
                >
                  {uploadFile}
                </label>
              </div>
              <label className="form-label">
                {imageSize}
                <span className="required">{requiredFeild.required}</span>
              </label>
              <a
                className="buttonsize"
                href={projectData.msa?.document_path_url}
                target="_blank"
              >
                {projectData.msa?.document_path_url ? (
                  <>
                    <div className="d-flex flex-row justify-content-start align-items-center">
                      <span>{viewFile}</span>
                      <img
                        className="cursor"
                        src={icons.close_icon}
                        onClick={() =>
                          setProjectData((obj: any) => ({
                            ...obj,
                            msa: "",
                          }))
                        }
                        alt="viewFile"
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </a>
              <p className="image-size-condition">{docFile.msa?.name}</p>
            </div>

            <div className="col-md-4">
              <label className="form-label">{uploadSow}</label>
              <div>
                <input
                  type="file"
                  className="form-control"
                  onChange={handleSubmitSowImage}
                  hidden
                  id="actual-btn-sow"
                />
                <label
                  htmlFor="actual-btn-sow"
                  className="btn btn-outline-secondary buttonsize"
                >
                  {uploadFile}
                </label>
              </div>
              <label className="form-label">
                {imageSize}
                <span className="required">{requiredFeild.required}</span>
              </label>
              <a
                className="buttonsize"
                href={projectData.sow?.document_path_url}
                target="_blank"
              >
                {projectData.sow?.document_path_url ? (
                  <>
                    <div className="d-flex flex-row justify-content-start align-items-center">
                      <span>{viewFile}</span>
                      <img
                        className="cursor"
                        src={icons.close_icon}
                        onClick={() =>
                          setProjectData((obj: any) => ({
                            ...obj,
                            sow: "",
                          }))
                        }
                        alt="view file"
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </a>
              <p className="image-size-condition">{docFile.sow?.name}</p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="modal-btn-wrapper mt-2 mx-auto">
              {checkerData == false ? (
                <></>
              ) : projectDetails.recordStatus == "Approved" ||
                projectDetails.recordStatus == "Rejected" ? (
                <></>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-success buttonsize"
                    onClick={() => {
                      handleApproval("Approve");
                    }}
                  >
                    {approve}
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger buttonsize"
                    onClick={() => {
                      handleRejectModal("Rejecte");
                    }}
                  >
                    {reject}
                  </button>
                </>
              )}
            </div>
            <div className="modal-btn-wrapper mt-5 mx-auto">
              <div>
                <button
                  className="btn cancel-btn buttonsize"
                  onClick={hideEditProject}
                >
                  {cancel}
                </button>
              </div>
              <div>
                <button
                  className={
                    btnDisable
                      ? "btn add-submit-button buttonsize"
                      : "btn add-submit-button btn-disabled buttonsize"
                  }
                  onClick={handleEditProjectSubmit}
                >
                  {submit}
                </button>
              </div>
            </div>
            <ModalComponents
              show={showReason}
              onHide={hideModal}
              title=""
              modalBody={
                <ModalWarning
                  apiCallCompleted={apiCallCompleted}
                  hideModal={hideModal}
                  header={modalWarning}
                  id={projectDetails.id}
                  editApi={actions.editProjectRepositoryAction}
                  data={{
                    ...projectData,
                    tdm: "",
                    hrbp: "",
                    status:
                      projectData?.status !== "New"
                        ? projectData?.status
                        : modalWarning + "d" === "Approved" &&
                          projectData?.status === "New"
                        ? "Ongoing"
                        : projectData.status,
                    recordStatus: modalWarning + "d",
                    approvedBy: loginId,
                  }}
                  hideEditModal={hideEditProject}
                  setShowUndoPopup={() => {}}
                />
              }
              className="modal-md"
            />
            <ModalComponents
              show={showReasonForRejection}
              onHide={hideRejectModal}
              title="Reason For Rejection"
              modalBody={
                <ReasonForRejection
                  apiCallCompleted={apiCallCompleted}
                  hideModal={hideRejectModal}
                  id={projectDetails.id}
                  editApi={actions.editProjectRepositoryAction}
                  statusKey="recordStatus"
                  hideEditModal={hideEditProject}
                  setShowUndoPopup={() => {}}
                  data={{
                    ...projectData,
                    tdm: "",
                    hrbp: "",
                    status:
                      modalWarning + "d" === "Rejected"
                        ? "New"
                        : projectData.status,
                    recordStatus: modalWarning + "d",
                    approvedBy: loginId,
                  }}
                />
              }
              className="modal-md"
            />
          </div>
        </>
      )}
    </>
  );
};

export default EditProject;
