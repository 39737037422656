import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as actions from "../../Redux/Actions";
import MainCard from "../../component/MainCard/MainCard";
import "./SkillLinks.scss";
import ModalComponents from "../../component/ModalComponent/ModalComponent";
import AddReferResource from "./AddReferResource";
import { ToastContainer } from "react-toastify";
import { showErrorToastMessage } from "../../component/Toasts/Toasts";
import useLoadingIndicator from "../../component/Loader/Loader";
import { icons } from "../../assets";
import { SkillLinksStrings, Strings } from "../../constant/Strings";
import BackButton from "../../component/Buttons/BackButton";
import CandidateRatingDesclaimer from "./CandidateRatingDesclaimer";
import { stripHtml } from "./HtmlToText";

const ReferSpecificJob = () => {
  const {
    loadingComponent,
    showLoadingIndicator,
    hideLoadingIndicator,
    apiCallCompleted,
  } = useLoadingIndicator();
  let { id } = useParams();
  const [info, setInfo] = useState<any>([]);
  const { jobStatus } = info;
  const dispatch = useDispatch();
  const { location_on, person_icon, contract_icon, work_icon } = icons;
  const { end_text } = SkillLinksStrings;
  const {
    refer_resource_title,
    major_skills,
    Job_description,
    download_jd,
    experience,
    referral_amount,
    ctc_range,
  } = Strings;
  useEffect(() => {
    showLoadingIndicator();
    dispatch(
      actions.getJobByJobIdAction(id, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res.error.error_description);
        } else if (res.code === 200) {
          hideLoadingIndicator();
        }
      })
    );
  }, []);

  const JobDetails = useSelector(
    (state: any) => state.employeeReducer.getJobByJobIdDetails
  );
  useEffect(() => {
    if (JobDetails) {
      if (JobDetails.data) {
        setInfo(JobDetails.data);
      }
    }
  }, [JobDetails, info]);
  const [showAddReferResource, setShowAddReferResource] = useState(false);
  const handleAddReferResource = () => {
    setShowAddReferResource(true);
  };
  const hideAddReferResource = () => {
    setShowAddReferResource(false);
  };
  const openDescURL = () => {
    info.uploadJobDescription?.document_path_url
      ? window.open(info.uploadJobDescription?.document_path_url, "_blank")
      : showErrorToastMessage("JD not Uploaded for this job");
  };

  return (
    <>
      {loadingComponent()}
      <MainCard>
        <BackButton navigateTo={"/refer-resource"} />
        <div className="heading-button-wrapper">
          <div>
            <h2>{refer_resource_title}</h2>
          </div>
          {info.uploadJobDescription?.document_path_url && (
            <div>
              <button
                disabled={jobStatus == 0}
                onClick={() => openDescURL()}
                className={
                  jobStatus == 1
                    ? "view-jd-btn"
                    : "view-jd-btn disable-outline-btn"
                }
              >
                {download_jd}
              </button>
            </div>
          )}
        </div>
        <div className="card p-3 mt-2">
          <div className="d-flex">
            <h5 className="job-title col-10">{info.jobTitle}</h5>
            <div className="skill-links-btns justify-content-end mx-4">
              <button
                className={
                  jobStatus == "1"
                    ? "common-css approved-btn"
                    : jobStatus == "0"
                    ? "common-css rejected-btn"
                    : ""
                }
              >
                {jobStatus == 1 ? "Open" : jobStatus == 0 ? "Closed" : ""}
              </button>
            </div>
          </div>
          <div className="sub-title">Job Id : {info.jobId}</div>
          <div className="row">
            <div className="col-10 mt-2">
              <div className="row sub-title">
                <div className="col-3">
                  <img src={location_on} className="mx-2" alt="icon"></img>
                  {info.location}
                </div>
                <div className="col-2">
                  <img src={work_icon} className="mx-2" alt="icon"></img>
                  {info.modeOfWork}
                </div>
                <div className="col-2">
                  <img src={person_icon} className="mx-2" alt="icon"></img>
                  {info.workLevel}
                </div>
                <div className="col-5">
                  <img src={contract_icon} className="mx-2" alt="icon"></img>
                  {info.jobCategory}
                </div>
              </div>
            </div>
            <div className="col-2 time-span">{info.timeSpan}</div>
          </div>
          <div className="d-flex mt-3">
            <div className="sub-title">{major_skills} :</div>
            {info?.majorSkills?.map((value: any, index: any) => (
              <strong key={index} className="ux-list">
                <li className="ux-li button-gap mx-2">{value}</li>
              </strong>
            ))}
          </div>
          <div className="d-flex mt-3">
            <div className="col-4 d-flex">
              <div className="sub-title">{experience} :</div>
              <span className="job-desc mx-2">{info.experience}</span>
            </div>
            <div className="col-4 d-flex">
              <div className="sub-title">{referral_amount} :</div>
              <span className="job-desc mx-2">{info.referralAmount}</span>
            </div>
            <div className="col-4 d-flex">
              <div className="sub-title">{ctc_range} :</div>
              <span className="job-desc mx-2">{info.salaryRange}</span>
            </div>
          </div>
          <hr className="line-hr"></hr>
          <div>
            <div className="sub-title">{Job_description} :</div>
            <p className="job-desc mx-2 mt-2">
              {info.description == ""
                ? "Download JD for complete Job Details."
                : info?.isQuestlightPosted == 1
                ? stripHtml(info.description)
                : info.description}
            </p>
          </div>
          <div className="mt-3 d-flex justify-content-center">
            <button
              className={
                info.recordStatus == "Approved" && jobStatus == 1
                  ? "view-job-btn view-referral-list-btn"
                  : "view-job-btn view-referral-list-btn disable-button"
              }
              onClick={handleAddReferResource}
              disabled={info.recordStatus !== "Approved" || jobStatus == 0}
            >
              {"Refer Resource"}
            </button>
          </div>
        </div>
        <CandidateRatingDesclaimer description={end_text} />
      </MainCard>

      <ModalComponents
        show={showAddReferResource}
        onHide={hideAddReferResource}
        title="Refer a Candidate(Enter Candidate Details)"
        modalBody={
          <AddReferResource
            apiCallCompleted={apiCallCompleted}
            jobId={info._id}
            hideAddReferResource={hideAddReferResource}
          />
        }
        className="modal-lg"
      />

      <ToastContainer />
    </>
  );
};

export default ReferSpecificJob;
